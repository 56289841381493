import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BaseSignalStateService<T> {
  readonly state = signal<T[]>([]);

  refreshAll() {
    this.state.set([]);
  }

  forceRefresh() {
    this.state.set(this.state());
  }
}
